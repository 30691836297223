export default async function sendRequest(host, path, options = {}) {
  const headers = { ...(options.headers || {}) };

  const response = await fetch(`${host}${path}`, {
    method: 'POST',
    ...options,
    headers,
  });

  const data = await response.json();


  if (data.error) {
    throw new Error(data.error);
  }

  return data;
}