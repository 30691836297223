import CardUpload from './CardUpload';

export default function CardList({options, choice, readFields, setReadFields}) {

    function getDocUploadButtons() {
        const picked = options.filter((doc) => doc.value === choice)
        const buttonsComp = []
        if (picked[0] !== undefined && picked[0]['needed_cards'] !== undefined) {
            picked[0]['needed_cards'].map((doc) => buttonsComp.push(<div key={doc.name}>
                <CardUpload docName={doc.name} order={doc.order} neededFields={doc.fields} readFields={readFields} setReadFields={setReadFields} key={choice}/>
            <br /></div>))
        }

        return buttonsComp
      }

    return (
        <>
            {getDocUploadButtons()}
        </>
    )
}