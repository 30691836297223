import sendRequest from "./sendRequest";
import { getReaderRootUrl } from "./getRootUrl";

export const getCardData = () => {
    return sendRequest(getReaderRootUrl(), "/read", {
        method: "GET"
    })
}

export const getHealth = () => {
    return sendRequest(getReaderRootUrl(), "/health", {
        method: "GET"
    })
}