import Select from 'react-select'

function DocumentList({documentList, setUserChoice, resetReadFields}) {
    return (<>
    <Select 
        options={documentList} 
        onChange={(choice) => {
          setUserChoice(choice.value)
          resetReadFields()
        }}
    />
    </>)
}

export default DocumentList