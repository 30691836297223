import { PatchType, TextRun, patchDocument } from "docx";
import { formatOnlyFirstUpper } from "./util";

const fieldsForFormating = ["name", "Street", "Place", "GivenName", "Surname", "ParentGivenName", "PlaceOfBirth", "CommunityOfBirth", "StateOfBirth", "Community"]

export async function fillWordDocument(wordBin, readFields) {
    let patches = {}
    for (let key in readFields) {
        const val = formatField(key, readFields[key])
        patches[key] = {
            type: PatchType.PARAGRAPH,
            children: [new TextRun({text: val, underline: {type: "single"}})]
        } 
    }
    const res = await patchDocument(wordBin, {
        patches: patches
    })
    .then((doc) => {
        return doc
    })

    return res
}

function formatField(key, value) {
    const fieldName = key.split('_')[0]
    if (fieldsForFormating.includes(fieldName)) {
        value = formatOnlyFirstUpper(value)
    }

    return value
}