
import { useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { getCardData } from "../lib/api/reader";
import { getCase } from "../lib/api/filler";
import { cyrilicToLatin } from 'serbian-script-converter';

export default function CardUpload({docName, order, neededFields, readFields, setReadFields}) {

    const [isLoading, setIsLoading] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [isFailed, setIsFailed] = useState(false)

    async function handleClick() {
        setIsLoading(true)
        try {
            let cardData = await getCardData()
            const mapped = await mapReadFields(neededFields, cardData, order)
            let newReadFields = {
                ...readFields,
                ...mapped
            }

            setReadFields(newReadFields)
            setIsChecked(true)
            setIsFailed(false)
        } catch(error) {
            console.log(error)
            setIsFailed(true)
            setIsChecked(false)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="cardUpload">
            {docName} <button onClick={handleClick}>Učitaj</button>
            {isLoading ? <BeatLoader
                            loading={true}
                            size={5}
                        />
            : null}
            {isChecked ?  <>&#x2713;</> : null}
            {isFailed ? <>✖</> : null}
        </div>
    )
}



async function mapReadFields(neededFields, cardData, order) {
    toLatin(cardData)
    let mapped = {}
    for (let i in neededFields) {
        const field = neededFields[i]

        const nameAndCase = field.split("_")
        if (nameAndCase[0] === "name") {
            let firstName = cardData.GivenName
            let lastName = cardData.Surname
            let name = firstName.concat(" ", lastName)
            if (nameAndCase.length === 2) {
                if (cardData.Sex !== "M") {
                    firstName = await getCase(firstName, nameAndCase[1])
                    firstName = firstName.body
                    name = firstName + " " + lastName
                } else {
                    name = await getCase(name, nameAndCase[1])
                    name = name.body
                }
            }
            const f = field + "_" + order
            mapped[f] = name
            continue
        }

        let value = cardData[nameAndCase[0]]
        if (nameAndCase.length === 2) {
            value = await getCase(value, nameAndCase[1])
            value = value.body
        }

        const f = field + "_" + order
        mapped[f] = value
    }

    return mapped
}

function toLatin(cardData) {
    for (const filedName in cardData) {
        if (filedName === 'Portrait') {
            continue
        }
        cardData[filedName] = cyrilicToLatin(cardData[filedName])
    }
}