import './assets/App.css';
import React, {useEffect, useState} from 'react';
import DocumentList from './components/DocumentList';
import Button from './components/Button';
import { downloadFile, getDocForm } from './utils/fileDownloader';
// import { fillPdf } from './utils/pdf';
import CardList from './components/CardList';
import { getDocumentForms, logFill } from './lib/api/filler';
import { getHealth } from './lib/api/reader';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SyncLoader from "react-spinners/SyncLoader";
import { fillWordDocument } from './utils/word';
import { getDate } from './utils/util';

function App() {

  const [userChoice, setUserChoice] = useState("")
  const [options, setOptions] = useState([])
  const [readFields, setReadFileds] = useState({date: getDate()})
  const [fillLoading, setFillLoading] = useState(false)

  useEffect(() => {
    getDocumentForms().then(data => {
      let newOptions = []
      for (let key in data.body) {
        let v = data.body[key]
        newOptions.push({value: v.doc_id, label: v.name, url_pdf: v.document_url_pdf, url_docx: v.document_url_docx,  needed_cards: v.needed_cards})
      }
      setOptions(newOptions)
    })
    getHealth().then(data => {
      console.log(data)
    }).catch((error) => {
      toast.error("Pokrenite program za citanje kartica", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        toastId: "reader_not_running",
        transition: Bounce,
      });
    })
  }, [])

  async function saveWord() {
    setFillLoading(true)
    if (userChoice === "") {
      alert("Izaberite tip dokumenta")
    } else {
      let chosen = 0
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === userChoice) {
          chosen = i
        }
      }
      const docS3Bytes = await getDocForm(options[chosen].url_docx)
      const filledBytes = await fillWordDocument(docS3Bytes, readFields)
      const filledBlob = new Blob([filledBytes])

      const fileName = options[chosen].label + ".docx"
      downloadFile(filledBlob, fileName)
      logFill("1", fileName)
    }
    setFillLoading(false)
  }

  function resetReadFields() {
    setReadFileds({date: getDate()})
  }

  return (
    <>
      <ToastContainer style={{ margin: "40px"}}/>
      <div className="App">
        <DocumentList documentList={options} setUserChoice={setUserChoice} resetReadFields={resetReadFields}/>
        <CardList options={options} choice={userChoice} readFields={readFields} setReadFields={setReadFileds}/>
        <Button handleClick={saveWord} text={"Popuni"}/>
        <div style={{height:15}}>
          <SyncLoader 
            loading={fillLoading}
            size={10}
          />
        </div>
      </div>
    </>
  );
}

export default App;
