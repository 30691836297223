import styled from "styled-components";

function Button({handleClick, text}) {
    const buttonStyle = {
        width: "200px",
        height: "50px",
      };

    return (
        <>
            <ButtonComponent
                style={buttonStyle}
                onClick={handleClick}
                title="Fill document"
                color="#841584"
            >
                {text}
            </ButtonComponent>
        </>
    )
}

const theme = {
    blue: {
      default: "#3f51b5",
      hover: "#283593",
    },
    pink: {
      default: "#e91e63",
      hover: "#ad1457",
    },
  };

const ButtonComponent = styled.button`
  background-color: ${(props) => theme[props.theme].default};
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  border: 0; 
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${(props) => theme[props.theme].hover};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

ButtonComponent.defaultProps = {
    theme: "blue",
  };

export default Button