import sendRequest from "./sendRequest";
import { getFillerAPIRootUrl } from "./getRootUrl";

export const getDocumentForms = () => {
    return sendRequest(getFillerAPIRootUrl(), "/documentforms", {
        method: "GET"
    })
}

export const logFill = (customerID, document) => {
    const reqBody = {
        "CustomerID": customerID,
        "Document": document,
    }
    sendRequest(getFillerAPIRootUrl(), "/log-fill", {
        method: "PUT",
        body: JSON.stringify(reqBody),
        headers: {
            "Content-Type": "application/json",
          },
    })
}

export const getCase = (noun, requiredCase) => {
    const reqBody = {
        "Noun": noun,
        "Case": requiredCase,
    }

    return sendRequest(getFillerAPIRootUrl(), "/case", {
        method: "POST",
        body: JSON.stringify(reqBody),
        headers: {
            "Content-Type": "application/json",
          },
    })
}